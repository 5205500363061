/* html.msg,
body.msg,
div.msg,
span.msg {
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
} */

body.msg {
  background: url("http://shurl.esy.es/y") no-repeat fixed center;
  background-size: cover;
}

.fa-2x.msg {
  font-size: 1.5em;
}

.app.msg {
  position: relative;
  overflow: hidden;
  top: 19px;
  height: calc(100% - 38px);
  margin: auto;
  padding: 0;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .06), 0 2px 5px 0 rgba(0, 0, 0, .2);
}

.app-one.msg {
  background-color: #f7f7f7;
  height: calc(100vh - 220px);
  overflow: hidden;
  margin: 0 !important;
  padding: 0;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .06), 0 2px 5px 0 rgba(0, 0, 0, .2);
  display: -webkit-box;
}

.side.msg {
  padding: 0;
  margin: 0;
  height: 100%;
}
.side-one.msg {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  position: relative;
  display: block;
  top: 0;
}

.side-two.msg {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  position: relative;
  top: -100%;
  left: -100%;
  -webkit-transition: left 0.3s ease;
  transition: left 0.3s ease;

}


.heading.msg {
  padding: 10px 16px 10px 15px;
  margin: 0;
  height: 60px;
  width: 100%;
  background-color: #eee;
  z-index: 1000;
}

.heading-avatar.msg {
  padding: 0;
  cursor: pointer;

}

.heading-avatar-icon img.msg {
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

.heading-name.msg {
  padding: 0 !important;
  cursor: pointer;
}

.heading-name-meta.msg {
  font-weight: 700;
  font-size: 100%;
  padding: 5px;
  padding-bottom: 0;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
  display: block;
}
.heading-online.msg {
  display: none;
  padding: 0 5px;
  font-size: 12px;
  color: #93918f;
}
.heading-compose.msg {
  padding: 0;
}

.heading-compose i.msg {
  text-align: center;
  padding: 5px;
  color: #93918f;
  cursor: pointer;
}

.heading-dot.msg {
  padding: 0;
  margin-left: 10px;
}

.heading-dot i.msg {
  text-align: right;
  padding: 5px;
  color: #93918f;
  cursor: pointer;
}

.searchBox.msg {
  padding: 0 !important;
  margin: 0 !important;
  height: 60px;
  width: 100%;
}

.searchBox-inner.msg {
  height: 100%;
  width: 100%;
  padding: 10px !important;
  background-color: #fbfbfb;
}


/*#searchBox-inner input.msg {
  box-shadow: none;
}*/

.searchBox-inner input:focus.msg {
  outline: none;
  border: none;
  box-shadow: none;
}

.sideBar.msg {
  padding: 0 !important;
  margin: 0 !important;
  background-color: #fff;
  overflow-y: auto;
  border: 1px solid #f7f7f7;
  height: calc(100% - 120px);
}

.sideBar-body.msg {
  position: relative;
  padding: 10px !important;
  border-bottom: 1px solid #f7f7f7;
  height: 72px;
  margin: 0 !important;
  cursor: pointer;
}

.sideBar-body:hover.msg {
  background-color: #f2f2f2;
}

.sideBar-avatar.msg {
  text-align: center;
  padding: 0 !important;
}

.avatar-icon.msg img {
  border-radius: 50%;
  height: 49px;
  width: 49px;
}

.sideBar-main.msg {
  padding: 0 !important;
}

.sideBar-main .row.msg {
  padding: 0 !important;
  margin: 0 !important;
}

.sideBar-name.msg {
  padding: 10px !important;
}

.name-meta.msg {
  font-size: 100%;
  padding: 1% !important;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
}

.sideBar-time.msg {
  padding: 10px !important;
}

.time-meta.msg {
  text-align: right;
  font-size: 12px;
  padding: 1% !important;
  color: rgba(0, 0, 0, .4);
  vertical-align: baseline;
}

/*New Message*/

.newMessage {
  padding: 0 !important;
  margin: 0 !important;
  height: 100%;
  position: relative;
  left: -100%;
}
.newMessage-heading {
  padding: 10px 16px 10px 15px !important;
  margin: 0 !important;
  height: 100px;
  width: 100%;
  background-color: #00bfa5;
  z-index: 1001;
}

.newMessage-main {
  padding: 10px 16px 0 15px !important;
  margin: 0 !important;
  height: 60px;
  margin-top: 30px !important;
  width: 100%;
  z-index: 1001;
  color: #fff;
}

.newMessage-title {
  font-size: 18px;
  font-weight: 700;
  padding: 10px 5px !important;
}
.newMessage-back {
  text-align: center;
  vertical-align: baseline;
  padding: 12px 5px !important;
  display: block;
  cursor: pointer;
}
.newMessage-back i {
  margin: auto !important;
}

.composeBox.msg {
  padding: 0 !important;
  margin: 0 !important;
  height: 60px;
  width: 100%;
}

.composeBox-inner.msg {
  height: 100%;
  width: 100%;
  padding: 10px !important;
  background-color: #fbfbfb;
}

.composeBox-inner input:focus.msg {
  outline: none;
  border: none;
  box-shadow: none;
}

.compose-sideBar.msg {
  padding: 0 !important;
  margin: 0 !important;
  background-color: #fff;
  overflow-y: auto;
  border: 1px solid #f7f7f7;
  height: calc(100% - 160px);
}

/*Conversation*/

.conversation.msg {
  padding: 0 !important;
  margin: 0 !important;
  height: 100%;
  /*width: 100%;*/
  border-left: 1px solid rgba(0, 0, 0, .08);
  /*overflow-y: auto;*/
}

.message {
  padding: 0 !important;
  margin: 0 !important;
  /* background: url("w.jpg") no-repeat fixed center; */
  background-size: cover;
  overflow-y: auto;
  border: 1px solid #f7f7f7;
  height: calc(100% - 120px);
}
.message-previous {
  margin : 0 !important;
  padding: 0 !important;
  height: auto;
  width: 100%;
}
.previous.msg {
  font-size: 15px;
  text-align: center;
  padding: 10px !important;
  cursor: pointer;
}

.previous a.msg {
  text-decoration: none;
  font-weight: 700;
}

.message-body {
  margin: 0 !important;
  padding: 0 !important;
  width: auto;
  height: auto;
  display: block !important;
}

.message-main-receiver {
  /*padding: 10px 20px;*/
  max-width: 60%;
}

.message-main-sender {
  padding: 3px 20px !important;
  padding-left: 40% !important;
  max-width: 60%;
}

.message-text {
  margin: 0 !important;
  padding: 5px !important;
  word-wrap:break-word;
  font-weight: 200;
  font-size: 14px;
  padding-bottom: 0 !important;
}

.message-time {
  margin: 0 !important;
  padding-left: 50px !important;
  font-size: 12px;
  text-align: right;
  color: #9a9a9a;

}

.receiver {
  width: auto !important;
  padding: 4px 10px 7px !important;
  border-radius: 10px 10px 10px 0;
  background: #ffffff;
  font-size: 12px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
  word-wrap: break-word;
  display: inline-block;
}

.sender {
  float: right;
  width: auto !important;
  background: #dcf8c6;
  border-radius: 10px 10px 0 10px;
  padding: 4px 10px 7px !important;
  font-size: 12px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
  display: inline-block;
  word-wrap: break-word;
}


/*Reply*/

.reply.msg {
  height: 60px;
  width: 100%;
  background-color: #f5f1ee;
  padding: 10px 5px 10px 5px !important;
  margin: 0 !important;
  z-index: 1000;
  align-items: center;
}

.reply-emojis.msg {
  padding: 5px !important;
}

.reply-emojis i.msg {
  text-align: center;
  padding: 5px 5px 5px 5px !important;
  color: #93918f;
  cursor: pointer;
}

.reply-recording.msg {
  padding: 5px !important;
}

.reply-recording i.msg {
  text-align: center;
  padding: 5px !important;
  color: #93918f;
  cursor: pointer;
}

.reply-send.msg {
  padding: 5px !important;
}

.reply-send i.msg {
  text-align: center;
  padding: 5px !important;
  color: #93918f;
  cursor: pointer;
}

.reply-main.msg {
  padding: 2px 5px !important;
}

.reply-main textarea.msg {
  width: 100%;
  resize: none;
  overflow: hidden;
  padding: 5px !important;
  outline: none;
  border: none;
  text-indent: 5px;
  box-shadow: none;
  height: 100%;
  font-size: 16px;
}

.reply-main textarea:focus.msg {
  outline: none;
  border: none;
  text-indent: 5px;
  box-shadow: none;
}

.noti-icon .badge  {
  position: absolute;
  top: 0px;
  right: 25px;
  display: block;
  height: 18px;
  width: 18px;
  line-height: 14px;
  border-radius: 9px;
  background-color: #ec4561;
  color: white;
  text-align: center;
  padding: 0px;
}

.my-message-icon:before {
  content: "\f029";
}

.dropdown-menu.show.my, .dropdown-menu.dropdown-show.my {
  top: 20px !important;
  left: 30px !important;
  padding: 10px 20px !important;
  width: 100px !important;
  min-width: 100px !important;
  background-color:cornsilk;
  display: block;
  margin-top: 5px;
}

.dropdown-menu.show.my div, .dropdown-menu.dropdown-show.my div {
  padding: 5px 0px !important;
}

.dropdown-menu.show.my a, .dropdown-menu.dropdown-show.my a {
  color: black !important;
}

.dropdown-menu.show.my a:hover, .dropdown-menu.dropdown-show.my a:hover{
  color: blue !important;
}

.btn .badge.badge-me  {
  position: absolute;
  top: 30px;
  right: 0px;
  display: block;
  height: 12px;
  width: 12px;
  line-height: 14px;
  border-radius: 6px;
  background-color: #6CDC80;
  color: white;
  padding: 0px;
}

.avatar-icon.msg .badge {
  position: absolute;
  top: 0px;
  left: 35px;
  display: block;
  height: 18px;
  width: 18px;
  line-height: 16px;
  border-radius: 9px;
  background-color: #ec4561;
  color: white;
  text-align: center;
  padding: 0px;
}

.avatar-icon.msg .badge.badge-connect {
  position: relative;
  top: -12px;
  right: -35px;
  display: block;
  height: 14px;
  width: 14px;
  line-height: 16px;
  border-radius: 7px;
  background-color: #6CDC80;
  color: white;
  text-align: center;
  padding: 0px;
}

.btn .badge.badge-me.badge-status-away, .avatar-icon.msg .badge.badge-connect.badge-status-away  {
  background-color: #FBC42F;
}

.avatar-icon.msg .badge.badge-connect.badge-status-off  {
  background-color: #838383;
}

.badge {
  border: 2px solid white;
}

.dropdown-menu.my.dropdown-hide {
  display: none !important;
}

@media screen and (max-width: 700px) {
  .app.msg {
    top: 0;
    height: 100%;
  }
  .heading.msg {
    height: 70px;
    background-color: #009688;
  }
  .fa-2x.msg {
    font-size: 2.3em !important;
  }
  .heading-avatar.msg {
    padding: 0 !important;
  }
  .heading-avatar-icon img.msg {
    height: 50px;
    width: 50px;
  }
  .heading-compose.msg {
    padding: 5px !important;
  }
  .heading-compose i.msg {
    color: #fff;
    cursor: pointer;
  }
  .heading-dot.msg {
    padding: 5px !important;
    margin-left: 10px !important;
  }
  .heading-dot i.msg {
    color: #fff;
    cursor: pointer;
  }
  .sideBar.msg {
    height: calc(100% - 130px);
  }
  .sideBar-body.msg {
    height: 80px;
  }
  .sideBar-avatar.msg {
    text-align: left;
    padding: 0 8px !important;
  }
  .avatar-icon.msg img {
    height: 55px;
    width: 55px;
  }
  .sideBar-main.msg {
    padding: 0 !important;
  }
  .sideBar-main .row.msg {
    padding: 0 !important;
    margin: 0 !important;
  }
  .sideBar-name.msg {
    padding: 10px 5px !important;
  }
  .name-meta.msg {
    font-size: 16px;
    padding: 5% !important;
  }
  .sideBar-time.msg {
    padding: 10px !important;
  }
  .time-meta.msg {
    text-align: right;
    font-size: 14px;
    padding: 4% !important;
    color: rgba(0, 0, 0, .4);
    vertical-align: baseline;
  }
  /*Conversation*/
  .conversation.msg {
    padding: 0 !important;
    margin: 0 !important;
    height: 100%;
    /*width: 100%;*/
    border-left: 1px solid rgba(0, 0, 0, .08);
    /*overflow-y: auto;*/
  }
  .message {
    height: calc(100% - 140px);
  }
  .reply.msg {
    height: 70px;
  }
  .reply-emojis.msg {
    padding: 5px 0 !important;
  }
  .reply-emojis i.msg {
    padding: 5px 2px !important;
    font-size: 1.8em !important;
  }
  .reply-main.msg {
    padding: 2px 8px !important;
  }
  .reply-main textarea.msg {
    padding: 8px !important;
    font-size: 18px;
  }
  .reply-recording.msg {
    padding: 5px 0 !important;
  }
  .reply-recording i.msg {
    padding: 5px 0 !important;
    font-size: 1.8em !important;
  }
  .reply-send.msg {
    padding: 5px 0 !important;
  }
  .reply-send i.msg {
    padding: 5px 2px 5px 0 !important;
    font-size: 1.8em !important;
  }
}